<script setup lang="ts">
// globals
defineProps({
  showActions: {
    type: Boolean,
    default: false,
  },
})

// composables
const { formatNumber } = useFormatter()
const { getParties } = useStatic()
const router = useRouter()

// stores
const cartStore = useCartStore()
const orderStore = useOrderStore()

// static
const parties = getParties()

// refs
const {
  cartItems,
  election,
} = storeToRefs(cartStore)

// computed
const {
  expeditedShippingCost,
  showEstimatedCost,
  totalEstimatedCost,
} = storeToRefs(cartStore)
const isNassau = computed(() => election.value?.Customer?.County === 'Nassau')

// methods
const { removeItem } = cartStore
const { startOrder } = orderStore
async function remove(id: any) {
  const params = router.currentRoute.value?.params as any
  if (id?.toString() === params?.id)
    await router.push('/dashboard')

  await removeItem(id)
}
</script>

<template>
  <v-list class="pt-0 fill-width">
    <v-divider class="mt-2" />

    <template
      v-for="item in cartItems"
      :key="`ci-${item.ID}`"
    >
      <v-list-item class="px-2 py-2">
        <template #prepend>
          <v-icon
            color="primary"
            :icon="item?.Product?.ItemType === 'Material' ? '$mdiPaperRoll' : '$mdiBallot'"
            size="36"
          />
        </template>

        <template #title>
          <v-list-item-title class="font-weight-medium text-primary">
            {{ item.Description }}
          </v-list-item-title>
        </template>

        <template #subtitle>
          <div class="d-flex flex-column font-weight-bold">
            <div v-if="item?.ItemQuantity">
              {{ formatNumber(item.ItemQuantity) }}
              {{
                item.ItemType === 'Deck' && isNassau
                  ? 'DS200s'
                  : item.Product.ItemType === 'Material'
                    ? 'Piece'
                    : item.ItemType
              }}{{ item.ItemQuantity > 1 ? 's' : '' }}
            </div>
            <div v-if="item?.SampleQuantity">
              {{ formatNumber(item.SampleQuantity) }} Sample Ballots
            </div>
            <div v-if="item?.TestQuantity">
              {{ formatNumber(item.TestQuantity) }}
              {{ item.ItemType === 'Deck' && isNassau ? 'Automark' : 'Test Ballot' }}{{ item.TestQuantity > 1 ? 's' : '' }}
            </div>
          </div>
          <div v-if="item?.Product?.ItemType === 'Deck' && !isNassau">
            Premarked
          </div>
          <div v-if="item?.Product?.ItemType !== 'Material'">
            {{ item.Product.Scannable ? 'Scannable' : 'Non Scannable' }}
          </div>
          <div v-if="item?.Product?.BallotCreation">
            w/ Ballot Creation
          </div>
          <div v-if="item?.Product?.Stub">
            w/ Stub
          </div>
        </template>

        <template #append>
          <div class="d-flex flex-column fill-height justify-space-between align-end mt-n1">
            <div v-if="showActions">
              <v-btn
                color="warning"
                icon="$mdiPencil"
                size="x-small"
                :to="`/product/${item.ID}`"
                variant="text"
                @click="startOrder(election, item)"
              />
              <v-btn
                color="error darken-1"
                icon="$mdiCloseCircle"
                size="x-small"
                variant="text"
                @click="remove(item.ID)"
              />
            </div>

            <v-list-item-subtitle
              v-if="showEstimatedCost"
              class="d-flex align-end font-weight-medium text-red mr-2 pt-2"
            >
              {{
                !item.TotalCost
                  ? '*Pricing Varies'
                  : formatNumber(item.TotalCost, 'currency')
              }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="election && election.Type === 'Primary'"
              class="d-flex align-end font-weight-medium text-red mr-2 pt-2"
            >
              {{ parties[item.Party] || '' }}
            </v-list-item-subtitle>
          </div>
        </template>
      </v-list-item>
      <v-divider />
    </template>
  </v-list>

  <div
    v-if="expeditedShippingCost"
    class="d-flex justify-end align-center fill-width text-subtitle-2 font-weight-medium px-6 py-1"
  >
    <div class="align-right px-6">
      Expedited Test Shipping
    </div>
    <div class="text-red">
      {{ formatNumber(expeditedShippingCost, 'currency') }}
    </div>
  </div>

  <div
    v-if="showEstimatedCost"
    class="d-flex justify-end align-center fill-width text-subtitle-2 font-weight-medium px-6 py-1"
  >
    <div class="align-right px-4">
      Total Estimated Cost
    </div>
    <div class="text-red">
      {{ formatNumber(totalEstimatedCost, 'currency') }}
    </div>
  </div>
</template>

<style lang="sass" scoped>
  :deep(.v-list-item-subtitle)
    opacity: var(--v-high-emphasis-opacity) !important
    div
      display: -webkit-box
  :deep(.v-list-item__append)
    height: 100%
  :deep(.v-list-item__prepend)
    .v-icon
      opacity: var(--v-high-emphasis-opacity) !important
      margin-inline: 8px
</style>
