<script setup lang="ts">
// globals
defineEmits(['update:model-value'])

// composables
const { api } = useFeathers()

// stores
const authStore = useAuthStore()

// computed
const {
  isAdmin,
  user,
} = storeToRefs(authStore)
const computedCustomers = computed(() => {
  return isAdmin.value ? customers.data || [] : [user.value.Customer, ...user.value?.ChildCustomers || []]
})

// service params
const customerParams = computed(() => ({
  query: {
    $select: ['Account', 'CustName'],
    $sort: { CustName: 1 },
    ActiveFlag: true,
    CustomerTypeCode: {
      $in: ['ADMIN', 'CBOE', 'SCHOL', 'SPEC', 'VILAG'],
    },
  },
}))

// services
const customers = api.service('epms/customer').useFind(customerParams, { paginateOn: 'server' })
</script>

<template>
  <v-autocomplete
    clearable
    hide-no-data
    item-title="CustName"
    item-value="Account"
    :items="computedCustomers"
    :loading="customers?.isPending"
    @update:model-value="$emit('update:model-value', $event)"
  />
</template>

<style scoped lang="sass">
  :deep(.v-field__input)
    span
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
</style>
