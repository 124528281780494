import groupBy from 'lodash/groupBy'

export default () => {
  function calcTotal(items: any, selector: string | 0, group: string | null) {
    const groupedItems = group ? groupBy(items, 'DistrictName')[group] || [] : items
    const total = groupedItems.reduce((acc: number, item: Record<string, string>) => {
      acc += Number.parseInt(item[selector]) || 0
      return acc
    }, 0)
    return total
  }

  return {
    calcTotal,
  }
}
