export function useStatic() {
  function getParties() {
    return {
      Con: 'Conservative',
      Dem: 'Democratic',
      Rep: 'Republican',
      Wor: 'Working Families',
      Default: 'Party',
      // historical
      Gre: 'Green',
      Lib: 'Libertarian',
      Ref: 'Reform',
      Weq: 'Woman\'s Equality',
      Ind: 'Independence',
      Sam: 'SAM',
    } as Record<string, string>
  }

  function getStates(full = false) {
    return [
      `AL${full ? ' - Alabama' : ''}`,
      `AK${full ? ' - Alaska' : ''}`,
      `AZ${full ? ' - Arizona' : ''}`,
      `AR${full ? ' - Arkansas' : ''}`,
      `CA${full ? ' - California' : ''}`,
      `CO${full ? ' - Colorado' : ''}`,
      `CT${full ? ' - Connecticut' : ''}`,
      `DE${full ? ' - Delaware' : ''}`,
      `FL${full ? ' - Florida' : ''}`,
      `GA${full ? ' - Georgia' : ''}`,
      `HI${full ? ' - Hawaii' : ''}`,
      `ID${full ? ' - Idaho' : ''}`,
      `IL${full ? ' - Illinois' : ''}`,
      `IN${full ? ' - Indiana' : ''}`,
      `IA${full ? ' - Iowa' : ''}`,
      `KS${full ? ' - Kansas' : ''}`,
      `KY${full ? ' - Kentucky' : ''}`,
      `LA${full ? ' - Louisiana' : ''}`,
      `ME${full ? ' - Maine' : ''}`,
      `MD${full ? ' - Maryland' : ''}`,
      `MA${full ? ' - Massachusetts' : ''}`,
      `MI${full ? ' - Michigan' : ''}`,
      `MN${full ? ' - Minnesota' : ''}`,
      `MS${full ? ' - Mississippi' : ''}`,
      `MO${full ? ' - Missouri' : ''}`,
      `MT${full ? ' - Montana' : ''}`,
      `NE${full ? ' - Nebraska' : ''}`,
      `NV${full ? ' - Nevada' : ''}`,
      `NH${full ? ' - New Hampshire' : ''}`,
      `NJ${full ? ' - New Jersey' : ''}`,
      `NM${full ? ' - New Mexico' : ''}`,
      `NY${full ? ' - New York' : ''}`,
      `NC${full ? ' - North Carolina' : ''}`,
      `ND${full ? ' - North Dakota' : ''}`,
      `OH${full ? ' - Ohio' : ''}`,
      `OK${full ? ' - Oklahoma' : ''}`,
      `OR${full ? ' - Oregon' : ''}`,
      `PA${full ? ' - Pennsylvania' : ''}`,
      `RI${full ? ' - Rhode Island' : ''}`,
      `SC${full ? ' - South Carolina' : ''}`,
      `SD${full ? ' - South Dakota' : ''}`,
      `TN${full ? ' - Tennessee' : ''}`,
      `TX${full ? ' - Texas' : ''}`,
      `UT${full ? ' - Utah' : ''}`,
      `VT${full ? ' - Vermont' : ''}`,
      `VA${full ? ' - Virginia' : ''}`,
      `WA${full ? ' - Washington' : ''}`,
      `WV${full ? ' - West Virginia' : ''}`,
      `WI${full ? ' - Wisconsin' : ''}`,
      `WY${full ? ' - Wyoming' : ''}`,
    ]
  }

  return {
    getParties,
    getStates,
  }
}
