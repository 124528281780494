<script setup lang="ts">
const props = defineProps({
  additionalData: {
    type: Object || null,
    default: () => { return null },
  },
  options: {
    type: Object,
    default: () => { return {} },
  },
  modelValue: {
    type: Array,
    default: () => { return [] },
  } as any,
})
// globals
const emit = defineEmits(['update:model-value', 'uploading'])
// composables
const { api } = useFeathers()

// stores
const { snackbarAction } = useSnackbar()

// static
const dropzoneDefaults = {
  maxFileSize: 25000000,
  url: `${import.meta.env.VITE_API_ENDPOINT as string || 'http://localhost:4010'}/cc/fileUploads`,
  paramName: 'File',
}

// refs
const addCount = ref(0)
const uploadCount = ref(0)
const dropzone = ref(undefined) as any
const internal = ref([] as any)

// computed
const accessToken = ref('')
const dropzoneOptions = computed(() => ({
  ...Object.assign(dropzoneDefaults, {}),
  ...(props?.options || {}),
  headers: { Authorization: `Bearer ${accessToken.value || ''}` },
}))

// methods
async function addFile(item: any) {
  addCount.value++
  internal.value.push(item.file)
  emit('update:model-value', internal.value)
  emit('uploading', true)
}
async function errorAdd(item: any) {
  const error = {
    INVALID_TYPE: 'File(s) not added: Invalid file type',
    MAX_FILE_SIZE: 'File(s) not added: Max file size exceeded',
    MAX_FILE: 'File(s) not added: Max number of uploaded files exceeded',
  } as any
  snackbarAction(() => {
    throw new Error(error?.[item.error] || 'Error uploading files')
  })
}
async function removeFile(item: any) {
  internal.value = internal.value.filter((val: any) => {
    return val?.name !== item?.file?.name
  })
  emit('update:model-value', internal.value)
}
async function setData(files: File[], xhr: XMLHttpRequest, formData: FormData) {
  formData.append('Message', JSON.stringify(props?.additionalData || {}))
  /* could be used for getting upload ID
    xhr.addEventListener('loadend', (res: any) => {
      console.log(JSON.parse(res?.target?.response || ''))
    })
    */
}
async function uploaded(_items: any) {
  uploadCount.value++
  if (uploadCount.value >= addCount.value) {
    emit('uploading', false)
    snackbarAction(null, 'File uploading complete')
  }
}

// lifecycle
onMounted(async () => {
  accessToken.value = await api.authentication.getAccessToken() as string
  for (const file of props?.modelValue || [])
    internal.value.push({ ...file })
})
</script>

<template>
  <v-sheet
    height="230"
    style="overflow-y: auto"
  >
    <drop-zone
      id="custom-dropzone"
      ref="dropzone"
      v-bind="{ ...dropzoneOptions }"
      @added-file="addFile"
      @error-add="errorAdd"
      @removed-file="removeFile"
      @sending="setData"
      @uploaded="uploaded"
    >
      <template #message>
        <div
          class="d-flex justify-center align-center"
          style="height: 214px"
        >
          <div>
            <v-icon>$mdiUpload</v-icon>
            <h3 class="primary--text">
              Click to select a file
            </h3>
            <div class="baseText--text">
              ...or drag and drop from your desktop
            </div>
          </div>
        </div>
      </template>

      <template #remove>
        <div />
      </template>
    </drop-zone>
  </v-sheet>
</template>

<style lang="sass">
  #custom-dropzone
    border: unset
    padding: 8px
    display: flex
    flex-wrap: wrap
    justify-content: center

  .dropzone__item--style
    .dropzone__filename
      overflow: hidden
      text-overflow: ellipsis
      span
        border: none
</style>
