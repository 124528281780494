export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/usr/app/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/usr/app/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/activity-logs',
    name: '/activity-logs',
    component: () => import('/usr/app/src/pages/activity-logs.vue'),
    /* no children */
  },
  {
    path: '/checkout',
    name: '/checkout',
    component: () => import('/usr/app/src/pages/checkout.vue'),
    /* no children */
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/usr/app/src/pages/dashboard.vue'),
    /* no children */
  },
  {
    path: '/elections',
    name: '/elections',
    component: () => import('/usr/app/src/pages/elections.vue'),
    /* no children */
  },
  {
    path: '/matrixes',
    name: '/matrixes',
    component: () => import('/usr/app/src/pages/matrixes.vue'),
    /* no children */
  },
  {
    path: '/orders',
    /* internal name: '/orders' */
    /* no component */
    children: [
      {
        path: '',
        name: '/orders/',
        component: () => import('/usr/app/src/pages/orders/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/orders/[id]',
        component: () => import('/usr/app/src/pages/orders/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/preferences',
    name: '/preferences',
    component: () => import('/usr/app/src/pages/preferences.vue'),
    /* no children */
  },
  {
    path: '/pricing',
    name: '/pricing',
    component: () => import('/usr/app/src/pages/pricing.vue'),
    /* no children */
  },
  {
    path: '/product',
    /* internal name: '/product' */
    /* no component */
    children: [
      {
        path: ':id?',
        name: '/product/[[id]]',
        component: () => import('/usr/app/src/pages/product/[[id]].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/products',
    name: '/products',
    component: () => import('/usr/app/src/pages/products.vue'),
    /* no children */
  },
  {
    path: '/reports',
    name: '/reports',
    component: () => import('/usr/app/src/pages/reports.vue'),
    /* no children */
  },
  {
    path: '/settings',
    name: '/settings',
    component: () => import('/usr/app/src/pages/settings.vue'),
    /* no children */
  },
  {
    path: '/users',
    name: '/users',
    component: () => import('/usr/app/src/pages/users.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

